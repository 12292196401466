// import rotate from './rotateLocation.js'
//
// const RIOT_RAW_TRANSFORM = {
//   fracture: { swap: true, xadd: -1.47, yadd: -0.555, xmul: -1.04, ymul: 1.04 },
//   lotus: { swap: true, xadd: -1.31, yadd: -0.62, xmul: -0.965, ymul: 0.965 },
//   breeze: { swap: true, xadd: -1.255, yadd: -0.59, xmul: -0.925, ymul: 0.925 },
//   bind: { swap: true, xadd: -1.583, yadd: -0.362, xmul: -0.79, ymul: 0.79 },
//   ascent: { swap: false, xadd: -0.215, yadd: -0.025, xmul: 0.93, ymul: 0.93 },
//   haven: { swap: false, xadd: 0.0, yadd: 0.0, xmul: 1, ymul: 1 },
//   pearl: { swap: true, xadd: -1.24, yadd: -0.63, xmul: -1.04, ymul: 1.04 },
//   split: { swap: false, xadd: -0.283, yadd: 0.03, xmul: 1.04, ymul: 1.04 },
// }
// const convertFromRawRiot = (map, { x, y }) => {
//   const { xadd, yadd, xmul, ymul, swap } = RIOT_RAW_TRANSFORM[map.toLowerCase()]
//   x = (x + xadd) * xmul
//   y = (y + yadd) * ymul
//   return swap ? { x: y, y: x } : { x, y }
// }
//
// convertFromRawRiot(
//   mapData.name,
//   rotate(
//     {
//       x: 1 - (position.location.x * -7.5e-5 + 0.573242 * 1.1175),
//       y: position.location.y * 7.5e-5 + (0.813895 * 4.025) / 3,
//     },
//     -rotateDeg
//   )
// )

function convertRiotPositions(map, location) {
  if (location?.x == null || location?.y == null) {
    return null
  }

  if ('x_scal' in map) return {
    x: location.y * map.x_mult + map.x_scal,
    y: location.x * map.y_mult + map.y_scal,
  }

  return {
    x: location.y * map.xMultiplier + map.xScalarToAdd,
    y: location.x * map.yMultiplier + map.yScalarToAdd,
  }
}

export default convertRiotPositions
