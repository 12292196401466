import { RIOT_OUTCOME, RIOT_LIVESTAT_SLOT } from './gridMappings'
/**
 * @type {Record<string, API_ROUND_OUTCOME>}
 */
function getEventTime(event) {
  const timeStr =
    event?.metadata?.eventTime?.omittingPauses || event?.timestamp?.omittingPauses || event?.omittingPauses
  if (!timeStr) {
    console.warn('No event time', event)
    return null
  }
  if (timeStr.substring(timeStr.length - 1) !== 's') {
    console.error('Unexpected event time format', timeStr, event)
    throw new Error(`unhandled time format ${timeStr}`)
  }
  return Number.parseFloat(timeStr.substring(0, timeStr.length - 1)) * 1000
}

// eslint-disable-next-line no-unused-vars
function reducePlayer(state, event, rootState, rootEvent) {
  if (event) {
    return {
      id: `${event.playerId.value}`,
      puuid: `${event.accountId.type}:${event.accountId.value}`,
      name: event.displayName,
      type: event.type,
      agentId: event.selectedAgent?.fallback?.guid.toLowerCase(),
    }
  }
  return state
}

// eslint-disable-next-line no-unused-vars
function reduceTeam(state, event, rootState, rootEvent) {
  if (event) {
    return {
      id: `${event.teamId.value}`,
      name: event.name,
      players: event.playersInTeam.map(p => `${p.value}`),
    }
  }
  return state
}

// eslint-disable-next-line no-unused-vars
function reduceConfiguration(state, event, rootState, rootEvent) {
  let newState = state

  if (event?.players) {
    if (newState?.players && newState.players.length !== event.players.length) {
      console.log('player count change', newState.players.length, event.players.length, newState.players, event)
    }
    newState = {
      ...newState,
      players: event.players.map((p, i) => reducePlayer(newState?.players?.[i], p, state, event)),
    }
  }

  if (event?.selectedMap?.fallback?.guid) {
    if (newState?.selectedMap && newState.selectedMap !== event.selectedMap.fallback.guid) {
      console.log('selectedMap change', newState.selectedMap, event.selectedMap.fallback.guid, newState, event)
    }
    newState = { ...newState, selectedMap: event.selectedMap.fallback.guid }
  }

  if (event?.teams) {
    newState = { ...newState, teams: event.teams.map((t, i) => reduceTeam(newState?.teams?.[i], t, state, event)) }
  }

  return newState
}

// eslint-disable-next-line no-unused-vars
function reduceStaticMetadata(state, event, rootState, rootEvent) {
  let newState = state

  if (event?.gameId?.value) {
    if (newState?.gameId && newState.gameId !== event.gameId.value) {
      console.error('Game ID mismatch', newState.gameId, event.gameId, event)
      throw new Error('Game ID mismatch')
    }
    newState = { ...newState, gameId: event.gameId.value }
  }

  if (event?.gameVersion) {
    if (newState?.gameVersion && newState.gameVersion !== event.gameVersion) {
      console.error('Game version mismatch', newState.gameVersion, event.gameVersion, event)
      throw new Error('Game version mismatch')
    }
    newState = { ...newState, gameVersion: event.gameVersion }
  }

  return newState
}

// eslint-disable-next-line no-unused-vars
function reduceGamePhase(state, event, rootState, rootEvent) {
  let newState = state

  if (event?.roundNumber != null) {
    if (newState?.roundNumber != null && newState.roundNumber !== event.roundNumber) {
      console.log('roundNumber change', newState.roundNumber, event.roundNumber)
    }
    if (newState?.roundNumber == null || newState.roundNumber !== event.roundNumber) {
      newState = { ...newState, roundTime: getEventTime(rootEvent) }
    }
    newState = { ...newState, roundNumber: event.roundNumber }
  }

  if (event?.phase) {
    if (newState?.phase && newState.phase !== event.phase) {
      console.log('phase change', newState.phase, event.phase)
    }
    if (newState?.phase == null || newState.phase !== event.phase) {
      newState = { ...newState, phaseTime: getEventTime(rootEvent) }
    }
    newState = { ...newState, phase: event.phase }
  }

  return newState
}

// eslint-disable-next-line no-unused-vars
function reducePlayerPositions(state, event, rootState, rootEvent) {
  let newState = state

  if (event?.snapshot?.players?.length) {
    event.snapshot.players.forEach(player => {
      if (player?.timeseries?.length) {
        player.timeseries.forEach(timeseries => {
          newState.rounds[newState.gamePhase.roundNumber].phases[newState.gamePhase.phase].positions.push({
            playerId: `${player.playerId.value}`,
            gameTime: getEventTime(timeseries),
            location: {
              x: timeseries.position.x,
              y: timeseries.position.y,
            },
          })
          newState.minX = newState.minX != null ? Math.min(newState.minX, timeseries.position.x) : timeseries.position.x
          newState.maxX = newState.maxX != null ? Math.max(newState.maxX, timeseries.position.x) : timeseries.position.x
          newState.minY = newState.minY != null ? Math.min(newState.minY, timeseries.position.y) : timeseries.position.y
          newState.maxY = newState.maxY != null ? Math.max(newState.maxY, timeseries.position.y) : timeseries.position.y
        })
      } else if (player?.aliveState?.position) {
        newState.rounds[newState.gamePhase.roundNumber].phases[newState.gamePhase.phase].positions.push({
          playerId: `${player.playerId.value}`,
          gameTime: getEventTime(rootEvent),
          location: {
            x: player.aliveState.position.x,
            y: player.aliveState.position.y,
          },
        })
        newState.minX =
          newState.minX != null ? Math.min(newState.minX, player.aliveState.position.x) : player.aliveState.position.x
        newState.maxX =
          newState.maxX != null ? Math.max(newState.maxX, player.aliveState.position.x) : player.aliveState.position.x
        newState.minY =
          newState.minY != null ? Math.min(newState.minY, player.aliveState.position.y) : player.aliveState.position.y
        newState.maxY =
          newState.maxY != null ? Math.max(newState.maxY, player.aliveState.position.y) : player.aliveState.position.y
      }
    })
  }

  return newState
}

function reduceAbilityUsed(state, event, rootState, rootEvent) {
  let newState = state

  if (event?.abilityUsed?.ability?.fallback?.inventorySlot?.slot) {
    if (!RIOT_LIVESTAT_SLOT[event.abilityUsed.ability.fallback.inventorySlot.slot]) {
      console.error('unknown ability used slot', event.abilityUsed.ability.fallback.inventorySlot.slot, event)
      return
    }
    newState.rounds[newState.gamePhase.roundNumber].phases[newState.gamePhase.phase].abilities.push({
      playerId: `${event.abilityUsed.playerId.value}`,
      gameTime: getEventTime(rootEvent),
      abilitySlot: RIOT_LIVESTAT_SLOT[event.abilityUsed.ability.fallback.inventorySlot.slot],
      chargesConsumed: event.abilityUsed.chargesConsumed,
    })
  }

  return newState
}

function reduceKills(state, event, rootState, rootEvent) {
  let newState = state

  if (event?.playerDied) {
    if (!event.playerDied.weapon && !event.playerDied.ability) {
      console.warn('unhandled playerDied event', event)
    } else if (event.playerDied.ability && !RIOT_LIVESTAT_SLOT[event.playerDied.ability.fallback.inventorySlot.slot]) {
      console.error('unknown ability kill event', event)
      console.log('inventory slot', event.playerDied.ability.fallback.inventorySlot.slot)
    }
    newState.rounds[newState.gamePhase.roundNumber].phases[newState.gamePhase.phase].kills.push({
      killerId: `${event.playerDied.killerId.value}`,
      victimId: `${event.playerDied.deceasedId.value}`,
      gameTime: getEventTime(rootEvent),
      assistantIds: (event.playerDied.assistants || []).map(a => `${a.value}`),
      finishing_damage: event.playerDied.weapon
        ? {
            damage_type: 'Weapon',
            damage_item: event.playerDied.weapon.fallback.guid,
          }
        : event.playerDied.ability
        ? {
            damage_type: 'Ability',
            damage_item: RIOT_LIVESTAT_SLOT[event.playerDied.ability.fallback.inventorySlot.slot],
          }
        : {},
    })
  }

  return newState
}

function reduceRoundOutcome(state, event) {
  let newState = state

  if (event?.roundDecided?.result) {
    const roundResult = event.roundDecided.result
    if (!newState.rounds[roundResult.roundNumber - 1]) {
      console.warn('round result for unknown round', roundResult.roundNumber, event, state)
    }

    const newRound = (newState.rounds[roundResult.roundNumber - 1] = {
      ...newState.rounds[roundResult.roundNumber - 1],
    })

    if (!RIOT_OUTCOME[roundResult.spikeModeResult.cause]) {
      console.error('unknown round outcome', roundResult.spikeModeResult.cause, event)
    } else {
      newRound.outcome = RIOT_OUTCOME[roundResult.spikeModeResult.cause]
    }
    if (roundResult.winningTeam?.value == null) {
      console.warn('missing winning team', roundResult, event)
    } else {
      newRound.winningTeam = `${roundResult.winningTeam.value}`
      newRound.winningRole =
        roundResult.winningTeam.value === roundResult.spikeModeResult.attackingTeam.value
          ? 'atk'
          : roundResult.winningTeam.value === roundResult.spikeModeResult.defendingTeam.value
          ? 'def'
          : null
    }
  }

  return newState
}

// eslint-disable-next-line no-unused-vars
function reduceRoundStarted(state, event, rootState, rootEvent) {
  if (!event?.spikeMode) return state

  rootState.rounds[event.roundNumber - 1] = {
    ...rootState.rounds[event.roundNumber - 1],
    teams: [
      {
        id: `${event.spikeMode.attackingTeam.value}`,
        role: 'atk',
      },
      {
        id: `${event.spikeMode.defendingTeam.value}`,
        role: 'def',
      },
    ],
  }

  return state
}

// eslint-disable-next-line no-unused-vars
function reduceGameId(state, event, rootState, rootEvent) {
  if (!event?.value) return state
  const newGameId = event.value
  if (newGameId !== state) {
    console.log('game id change', state, newGameId)
  }
  return newGameId
}

function reduceGridRiotEvents(rootState, event) {
  const gameId = reduceGameId(rootState?.currentGameId, event?.metadata?.gameId, rootState, event)
  if (!gameId) {
    return rootState
  }
  const state = rootState?.games?.[gameId]

  let newState = {
    ...state,
    configuration: reduceConfiguration(state?.configuration, event?.configuration, state, event),
    metadata: reduceStaticMetadata(state?.metadata, event?.metadata, state, event),
  }

  if (event?.metadata?.stage != null) {
    if (newState.stage != null && newState.stage !== event.metadata.stage) {
      console.log('stage change', newState.stage, event.metadata.stage, event)
    }
    newState = { ...newState, stage: event.metadata.stage }
  }

  newState = reduceRoundStarted(newState, event?.roundStarted, newState, event)

  const gamePhase = reduceGamePhase(newState?.gamePhase, event?.gamePhase, newState, event)
  if (gamePhase !== newState?.gamePhase) {
    newState = {
      ...newState,
      gamePhase,
      rounds: [
        ...(newState?.rounds?.slice(0, gamePhase?.roundNumber) || []),
        {
          startTime: gamePhase?.roundTime,
          ...newState.rounds?.[gamePhase?.roundNumber],
          phases: {
            ...newState.rounds?.[gamePhase?.roundNumber]?.phases,
            [gamePhase?.phase]: {
              startTime: gamePhase?.phaseTime,
              abilities: [],
              positions: [],
              kills: [],
            },
          },
        },
        ...(newState?.rounds?.slice(gamePhase?.roundNumber + 1) || []),
      ],
    }
  }

  newState = reducePlayerPositions(newState, event, newState, event)
  newState = reduceAbilityUsed(newState, event, newState, event)
  newState = reduceKills(newState, event, newState, event)
  newState = reduceRoundOutcome(newState, event, newState, event)

  return {
    ...rootState,
    currentGameId: gameId,
    games: {
      ...rootState?.games,
      [gameId]: newState,
    },
  }
}

reduceGridRiotEvents.INIT = Object.seal(Object.freeze({}))

export default reduceGridRiotEvents
