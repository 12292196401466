<template>
  <ApiLoadingController class="map-wrapper" :fetch="fetch" :params="params">
    <template #default="{ data }">
      <MapController
        v-bind="data"
        :notes="bookmarks"
        :initial-state="initialState"
        @update:initialState="updateInitialState"
      />
    </template>
  </ApiLoadingController>
</template>

<script>
// globals TransformStream

import { mapActions, mapGetters } from 'vuex'

import mapPage from '@/pages/mixins/mapPage'
import calcTeamEco from '@/utils/calcTeamEco'

import {
  getEmeaBroadcastMatch,
  getEmeaMatchBookmarks,
  getGridRiotLiveEventsTimeSeries,
  getRiotLiveStatsMatchInfo,
  gridEmeaSeriesStateMapTool,
  setEmeaMatchBookmarks,
  getGridSeriesInfo,
} from '../../api/grid.js'
import { getMatchReplayInfo } from '../../api/matches'
import { getMatchRoundAdvancedData } from '../../api/matches/analytics.js'
import axios from '../../axios.js'
import ApiLoadingController from '../../components/controllers/ApiLoadingController.vue'
import MapController from '../../components/Map/MapController.vue'
import genRoundId from '../../components/Map/utils/genRoundId.js'
import abbrv from '../../utils/abbrv.js'
import { convertMinimapData } from '../../utils/convertMinimapData.js'
import convertRiotPositions from '../../utils/convertRiotPositions.js'
import findEsportMatchesFromGridSeries from '../../utils/findEsportMatchesFromGridSeries.js'
import prepareMinimapDetectedData from '../../utils/prepareMinimapDetectedData.js'
import processMapToolData from '../../utils/processMapToolData.js'
import rotate from '../../utils/rotateLocation.js'

const INVENTORY_SLOTS_PRIORITY = {
  PRIMARY: 2,
  SECONDARY: 1,
}

export default {
  name: 'GridMapPage',
  mixins: [mapPage],
  components: { MapController, ApiLoadingController },
  props: {
    ids: Array,
    apiKey: String,
    localCache: Boolean,
    mapName: String,
    pos: String,
  },
  data: () => ({
    bookmarks: [],
  }),
  computed: {
    ...mapGetters({
      agentsById: 'static/agentsById',
      agentsByModelName: 'static/agentsByModelName',
      agentsByName: 'static/agentsByName',
      agentsByNameLowercase: 'static/agentsByNameLowercase',
      agentsList: 'static/agentsList',
      can_access_public_matches: 'auth/can_access_public_matches',
      gearsList: 'static/gearsList',
      gridKey: 'auth/grid_key',
      mapsByAnything: 'static/mapsByAnything',
      mapsByNameLowecase: 'static/mapsByNameLowecase',
      mapsByUrl: 'static/mapsByUrl',
      weaponsList: 'static/weaponsList',
    }),
    params() {
      return { ids: this.ids, mapName: this.mapName, apiKey: this.apiKey || this.gridKey }
    },
  },
  watch: {
    params: {
      handler() {
        this.bookmarks = []
        this.loadBookmarks()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      fetchMapAdvancedData: 'map/fetchMapAdvancedData',
    }),
    async loadBookmarks() {
      const allBookmarks = await Promise.all(
        this.ids.map(async id => {
          try {
            return await getEmeaMatchBookmarks({ seriesId: id, mapName: this.mapName })
          } catch (e) {
            console.warn(`Failed to load bookmarks for ${id} ${this.mapName}`, e)
            return []
          }
        })
      )
      this.bookmarks = Object.freeze(
        allBookmarks
          .flat()
          .filter(Boolean)
          .map(o => Object.freeze(o))
      )
    },
    async createBookmark(bookmark) {
      bookmark = Object.freeze(bookmark)
      this.bookmarks = Object.freeze([...this.bookmarks, bookmark])
      await setEmeaMatchBookmarks({ seriesId: this.ids[0], mapName: this.mapName, bookmarks: this.bookmarks })
    },
    async fetch({ apiKey, ids, mapName }, config) {
      config = { ...config, apiKey }
      const rawInput = await Promise.all(
        // eslint-disable-next-line no-unused-vars
        ids.map(async id => {
          const [state, { esportMatch, esportData, ...info } = {}, emea, reducedInfo, gridSeriesInfo] =
            await Promise.all([
              getGridRiotLiveEventsTimeSeries({ seriesId: id, mapName }, { ...config, localCache: this.localCache }),
              (async () => {
                const map = this.mapsByAnything[mapName]
                if (!map) {
                  return
                }

                const info = await gridEmeaSeriesStateMapTool({ id }, config)
                const gameInfo = info.data.seriesState.games.find(g => this.mapsByAnything[g.map.name] === map)
                const firstRoundInfo = gameInfo.segments.reduce((fnd, cur) =>
                  cur.started && cur.ended && cur.sequenceNumber <= fnd.sequenceNumber ? cur : fnd
                )

                let esportMatch
                if (this.can_access_public_matches) {
                  try {
                    const esportMatches = await findEsportMatchesFromGridSeries(info.data.seriesState, config, map.id)
                    esportMatch = esportMatches?.find(m => m?.map === map.id)
                  } catch (e) {
                    console.debug('failure finding esport matches', e)
                  }
                }

                let esportData
                if (esportMatch) {
                  try {
                    const [esportReplayInfo, esportRoundsAdvancedData] = await Promise.all([
                      getMatchReplayInfo({ matchId: esportMatch.id }, config),
                      Promise.all(
                        Array.from({ length: esportMatch.rounds_played }, (_, roundNum) =>
                          getMatchRoundAdvancedData({ matchId: esportMatch.id, roundNum }, config)
                        )
                      ),
                    ])
                    esportData = { esportReplayInfo, esportRoundsAdvancedData }
                  } catch (e) {
                    console.error('failure loading esport map data', e)
                  }
                }

                return {
                  ...gameInfo,
                  firstRound: firstRoundInfo,
                  esportMatch,
                  esportData,
                }
              })(),
              (async () => {
                try {
                  return await getEmeaBroadcastMatch({ id })
                } catch (e) {
                  console.debug('failure loading emea data', e)
                }
              })(),
              (async () => {
                try {
                  return await getRiotLiveStatsMatchInfo({ id, map: this.mapsByNameLowecase[mapName].name }, { apiKey })
                } catch (e) {
                  console.debug('failure loading riot live stats match info', e)
                }
              })(),
              (async () => {
                try {
                  return (await getGridSeriesInfo({ seriesId: id }, config))?.data?.series
                } catch (e) {
                  console.debug('failure loading grid series info', e)
                }
              })(),
            ])

          return {
            state,
            info,
            emea,
            seriesId: id,
            matchId: `${id}.${this.mapsByAnything[state.configuration.selectedMap].name}`,
            reducedInfo,
            esportMatch,
            gridSeriesInfo,
            ...esportData,
          }
        })
      )

      window.rawInput = rawInput

      console.log('rawInput', rawInput)
      const mapData = this.mapsByUrl[rawInput[0].state.configuration.selectedMap]
      const map = mapData.id

      console.log('mapData', mapData)

      // console.log(
      //   mapData.name,
      //   rawInput.flatMap(({ state }) =>
      //     state.rounds.flatMap(round =>
      //       [
      //         ...(round.phases.ROUND_STARTING?.positions || []),
      //         ...(round.phases.IN_ROUND?.positions || []),
      //         ...(round.phases.ROUND_ENDING?.positions || []),
      //       ].map(position => [position.location.x, position.location.y])
      //     )
      //   )
      // )

      const relatedData = rawInput.map(
        ({
          state,
          info,
          emea,
          seriesId,
          matchId,
          reducedInfo,
          esportMatch,
          esportReplayInfo,
          esportRoundsAdvancedData,
          gridSeriesInfo,
        }) => ({
          seriesId,
          state,
          info,
          emeaState: emea?.find(g => g.map === mapData.name.toLowerCase()),
          matchId,
          reducedInfo,
          esportMatch,
          esportReplayInfo,
          esportRoundsAdvancedData,
          gridSeriesInfo,
          teams: state.configuration.teams
            .map(stateTeam => ({
              stateTeam,
              firstRoundTeam: state.rounds[0].teams.find(t => t.id === stateTeam.id),
              score: state.rounds.filter(r => r.winningTeam === stateTeam.id).length,
            }))
            .map(({ stateTeam, firstRoundTeam, score }) => ({
              stateTeam,
              grid: firstRoundTeam.role === 'atk' ? 'Red' : 'Blue',
              score,
              infoTeam: info.teams.find(
                t =>
                  t.id ===
                  info.firstRound.teams.find(
                    tt => tt.side === (firstRoundTeam.role === 'atk' ? 'attacker' : 'defender')
                  ).id
              ),
            }))
            .map(({ stateTeam, grid, score, infoTeam }) => ({
              id: infoTeam.id,
              gameTeamId: stateTeam.id,
              abbr: this.$store.getters['static/getTeamAbbrByName'](infoTeam.name) || abbrv(infoTeam.name),
              name: infoTeam.name,
              grid: grid,
              logo_url: gridSeriesInfo?.teams?.find(t => t.baseInfo?.id === infoTeam.id)?.baseInfo?.logoUrl,
              team_side: grid,
              rounds_won: score,
              players: stateTeam.players
                .map(pid => state.configuration.players.find(p => p.id === pid))
                .filter(player => player.type === 'HUMAN')
                .map(statePlayer => ({
                  statePlayer,
                  infoPlayer: infoTeam.players.find(
                    p =>
                      p.participationStatus === 'active' &&
                      this.agentsByNameLowercase[p.character?.id]?.id === statePlayer.agentId
                  ),
                }))
                .map(({ statePlayer, infoPlayer }) => ({
                  id: infoPlayer?.id || statePlayer.puuid,
                  puuid: statePlayer.id,
                  name: infoPlayer?.name || statePlayer.name,
                  game_name: statePlayer.name,
                  agent: statePlayer.agentId,
                  agent_id: statePlayer.agentId,
                  team_side: grid,
                  // todo: add this if possible
                  avr_round_score: 0,
                })),
            })),
        })
      )

      const resolveUrl = async url => {
        try {
          return await axios.get(url)
        } catch (e) {
          console.log('failed to resolve url', url, e)
        }
      }

      const matchesData = await Promise.all(
        relatedData.map(
          async ({
            matchId,
            state,
            teams,
            emeaState,
            seriesId,
            reducedInfo,
            esportReplayInfo,
            esportRoundsAdvancedData,
            gridSeriesInfo,
          }) => ({
            id: matchId,
            created: Date.now(), // TODO: extract from events
            ...(gridSeriesInfo && gridSeriesInfo?.tournament
              ? {
                  event: {
                    id: gridSeriesInfo.tournament.id,
                    logo_url: gridSeriesInfo.tournament.logoUrl,
                    name: gridSeriesInfo.tournament.name,
                    nameShortened: gridSeriesInfo.tournament.nameShortened,
                  },
                }
              : {}),
            teams,
            rounds: state.rounds
              .map((round, roundNum) => ({
                round,
                roundNum,
                reducedInfoRound:
                  reducedInfo?.matchRounds?.find(r => r.roundNumber === roundNum + 1) ||
                  reducedInfo?.generalRounds?.find(r => r.roundNumber === roundNum + 1),
              }))
              .map(({ round, roundNum, reducedInfoRound }) => ({
                round_num: roundNum,
                round_length_millis:
                  (round.phases.ROUND_ENDING?.startTime || 0) + 7000 - (round.phases.IN_ROUND?.startTime || 0),
                result_code: round.outcome,
                plant_side: reducedInfoRound?.plantSite,
                plant_round_millis: reducedInfoRound?.plantTick
                  ? (reducedInfoRound?.plantTick - reducedInfoRound?.buyPhaseEndTick) * 1000
                  : null,
                planter_puuid: reducedInfoRound?.plantSite ? `${reducedInfoRound?.planterId}` || 'atk' : null,
                defuser_puuid: reducedInfoRound?.defuseTick ? `${reducedInfoRound?.defuserId}` || 'def' : null,
                defuse_round_millis: reducedInfoRound?.defuseTick
                  ? (reducedInfoRound?.defuseTick - reducedInfoRound?.buyPhaseEndTick) * 1000
                  : null,
                teams: teams
                  .map(team => ({
                    team,
                    reducedInfoRoundTeam: reducedInfoRound?.endBuyPhaseLoadout?.find(
                      t => `${t.teamId}` === team.gameTeamId
                    ),
                    roundTeam: round.teams?.find(t => t.id === team.gameTeamId),
                  }))
                  .map(({ team, reducedInfoRoundTeam, roundTeam }) => ({
                    role:
                      roundTeam?.role ||
                      (reducedInfoRound?.atkTeamId
                        ? `reducedInfoRound.atkTeamId` === team.gameTeamId
                          ? 'atk'
                          : 'def'
                        : null),
                    won: round.winningTeam === team.gameTeamId,
                    team,
                    reducedInfoRoundTeam,
                  }))
                  .map(({ role, won, team, reducedInfoRoundTeam }) => ({
                    id: team.id,
                    grid: team.grid,
                    role,
                    won,
                    eco: calcTeamEco(reducedInfoRoundTeam?.inventoryValue, roundNum, true),
                  })),
                kills: reducedInfoRound?.kills
                  ? reducedInfoRound.kills
                      .filter(kill => kill?.type === 'kill')
                      .map(kill => ({
                        round_time_millis: (kill.tick - reducedInfoRound.buyPhaseEndTick) * 1000,
                        killer_puuid: kill.killerId,
                        killer_location: convertRiotPositions(mapData, { x: kill.killerX, y: kill.killerY }),
                        victim_puuid: kill.victimId,
                        victim_location: convertRiotPositions(mapData, { x: kill.victimX, y: kill.victimY }),
                        finishing_damage:
                          kill.damageType === 'WEAPON'
                            ? {
                                damage_type: 'Weapon',
                                damage_item: kill.weaponId,
                              }
                            : kill.damageType === 'ABILITY'
                            ? {
                                damage_type: 'Ability',
                                damage_item: kill.abilitySlot,
                              }
                            : {},
                      }))
                  : [...(round.phases.IN_ROUND?.kills || []), ...(round.phases.ROUND_ENDING?.kills || [])].map(
                      kill => ({
                        round_time_millis: kill.gameTime - round.phases.IN_ROUND.startTime,
                        killer_puuid: kill.killerId,
                        victim_puuid: kill.victimId,
                        finishing_damage: kill.finishing_damage,
                      })
                    ),
                // TODO: fill player stats
                player_stats: reducedInfoRound?.endBuyPhaseLoadout
                  ?.flatMap(t => t.players)
                  .map(player => ({
                    reducedInfoRoundPlayer: player,
                    matchPlayer: teams.flatMap(t => t.players).find(p => p.puuid === `${player.playerId}`),
                    startBuyPhaseRoundPlayer: reducedInfoRound.startBuyPhaseLoadout.find(
                      p => p.playerId === player.playerId
                    ),
                  }))
                  .map(({ reducedInfoRoundPlayer, matchPlayer, startBuyPhaseRoundPlayer }) => ({
                    puuid: `${reducedInfoRoundPlayer.playerId}`,
                    team_side: matchPlayer?.team_side,
                    // TODO: compute this
                    assists: null,
                    damages: [
                      {
                        bodyshots: 0,
                        headshots: 0,
                        legshots: 0,
                        total: 0,
                      },
                    ],
                    kills: null,
                    deaths: null,
                    loadout_value: reducedInfoRoundPlayer.inventoryValue,
                    remaining: reducedInfoRoundPlayer.money,
                    spent: startBuyPhaseRoundPlayer
                      ? startBuyPhaseRoundPlayer.money - reducedInfoRoundPlayer.money
                      : null,
                    armor_id: reducedInfoRoundPlayer.armorId,
                    weapon_id: reducedInfoRoundPlayer.inventory?.length
                      ? reducedInfoRoundPlayer.inventory.reduce((acc, item) =>
                          INVENTORY_SLOTS_PRIORITY[acc.slot] > INVENTORY_SLOTS_PRIORITY[item.slot] ? acc : item
                        )?.weaponId
                      : null,
                  })),
              })),
            advancedData: await Promise.all(
              emeaState
                ? emeaState.rounds
                    .filter(r => r.clips)
                    .map(async mlRound => ({
                      round_num: mlRound.num - 1,
                      minimap_video_url:
                        mlRound.clips.minimap &&
                        (
                          await resolveUrl(
                            `https://jzxvxq32t24zqtnhp7hu2zx56m0dehqb.lambda-url.us-east-1.on.aws/series/${seriesId}/clips/${mlRound.clips.minimap}/url`
                          )
                        ).data,
                      replay_video_url:
                        mlRound.clips.full &&
                        (
                          await resolveUrl(
                            `https://jzxvxq32t24zqtnhp7hu2zx56m0dehqb.lambda-url.us-east-1.on.aws/series/${seriesId}/clips/${mlRound.clips.full}/url`
                          )
                        ).data,
                      replay_video_start_time: 0,
                      replay_video_end_time: new Date(mlRound.end).getTime() - new Date(mlRound.start).getTime(),
                    }))
                : esportRoundsAdvancedData || []
            ),
            ...(emeaState
              ? {
                  replay: {
                    video_id: `/test-ml/stream`,
                    video_platform: 'augment',
                    cropper_status: 'succeeded',
                    // TODO: set correct values
                    video_start_time: 0,
                    video_end_time: 10000000,
                  },
                  vod_status: 'succeeded',
                }
              : esportReplayInfo
              ? {
                  replay: esportReplayInfo,
                  vod_status: esportReplayInfo.cropper_status,
                }
              : null),
          })
        )
      )
      console.log('matchesData', matchesData)

      const roundAdvancedPositionsData = {}
      const roundSmokesData = {}
      const roundUtilitiesData = {}
      const roundWallsData = {}
      const orbsData = Object.fromEntries(
        relatedData.map(({ matchId, reducedInfo }) => [
          matchId,
          reducedInfo?.matchRounds
            ?.filter(r => r?.roundNumber)
            .map(round => ({
              round_num: round.roundNumber - 1,
              ultimates: round.endBuyPhaseLoadout
                ?.flatMap(t => t.players)
                .filter(p => p?.abilities?.Ultimate?.maxCharges)
                .map(p => ({
                  puuid: `${p.playerId}`,
                  count: p.abilities.Ultimate.baseCharges,
                  max: p.abilities.Ultimate.maxCharges,
                })),
            })),
        ])
      )
      console.log('orbsData', orbsData)
      // const utilitiesUsage = {}

      relatedData
        .map(({ matchId, ...rest }) => ({ ...rest, matchId, matchData: matchesData.find(m => m.id === matchId) }))
        .forEach(
          ({ matchId, state: riotReducedData, emeaState, teams: matchTeams, matchData, esportRoundsAdvancedData }) => {
            riotReducedData.rounds
              .map((riotReducedRoundData, roundNum) => ({
                roundNum,
                roundData: emeaState?.rounds.find(r => r.num === roundNum + 1),
                preparedRoundData: matchData.rounds.find(round => round.round_num === roundNum),
                riotReducedRoundData,
              }))
              .forEach(({ roundNum, roundData, preparedRoundData, riotReducedRoundData }) => {
                const vodProcessedData = {}

                const roundId = genRoundId(matchId, roundNum)
                const teams = preparedRoundData?.teams
                if (!teams) return
                const matchPlayers = matchTeams.flatMap(team => team.players)
                const minimapData = convertMinimapData(
                  roundData?.inferences?.minimap ||
                    esportRoundsAdvancedData?.find(e => e.round_num === roundNum)?.minimap_detections_expand,
                  matchPlayers,
                  teams,
                  this.agentsList
                )

                vodProcessedData[roundId] = [
                  ...(this.pos === 'ml'
                    ? minimapData?.player_locations?.map(event => {
                        return { ...event, location: rotate(event.location, -mapData.rotate_deg), type: 'agent' }
                      }) || []
                    : this.pos === 'grid'
                    ? Object.entries(roundData?.player_states || {})
                        .map(([puuid, states]) => [
                          matchTeams.flatMap(t => t.players).find(p => p.id === puuid).puuid,
                          states,
                        ])
                        .flatMap(([puuid, states]) =>
                          states?.positions
                            .filter(
                              (pos, idx, list) =>
                                idx === 0 ||
                                pos.t - list[idx - 1].t > 1.75 ||
                                pos.xy[0] !== list[idx - 1].xy[0] ||
                                pos.xy[1] !== list[idx - 1].xy[1]
                            )
                            .map(pos => ({
                              type: 'agent',
                              puuid,
                              location: { x: pos.xy[0], y: pos.xy[1] },
                              round_time_millis: pos.t * 1000,
                              track_id: puuid,
                            }))
                        )
                    : riotReducedRoundData.phases.IN_ROUND?.startTime
                    ? [
                        ...(riotReducedRoundData.phases.ROUND_STARTING?.positions || []).slice(-20),
                        ...(riotReducedRoundData.phases.IN_ROUND?.positions || []),
                        ...(riotReducedRoundData.phases.ROUND_ENDING?.positions || []),
                      ].map(position => ({
                        type: 'agent',
                        puuid: position.playerId,
                        round_time_millis: position.gameTime - riotReducedRoundData.phases.IN_ROUND.startTime,
                        location: convertRiotPositions(mapData, position.location),
                        track_id: position.playerId,
                      }))
                    : []),
                  ...(minimapData?.ability_locations?.map(event => {
                    return { ...event, location: rotate(event.location, -mapData.rotate_deg), type: 'ability' }
                  }) || []),
                  ...(minimapData?.smoke_locations?.map(event => {
                    return { ...event, location: rotate(event.location, -mapData.rotate_deg), type: 'smoke' }
                  }) || []),
                  ...(minimapData?.spike_locations?.map(event => {
                    return { ...event, location: rotate(event.location, -mapData.rotate_deg), type: 'spike' }
                  }) || []),
                  ...(minimapData?.wall_vectors?.map(event => {
                    return {
                      ...event,
                      location: rotate(event.location, -mapData.rotate_deg),
                      vector: event.vector.map(l => rotate(l, -mapData.rotate_deg)),
                      type: 'wall',
                    }
                  }) || []),
                ]
                console.log(roundId, 'vodProcessedData', vodProcessedData)
                // utilitiesUsage[roundId] = Object.entries(roundData.player_states.abilities || {}).flatMap(
                //   ([puuid, abilities]) =>
                //     Object.entries(abilities).flatMap(([abilityGridName, states]) => {
                //       const ability = this.abilitiesByGridName[abilityGridName]
                //       if (!ability) {
                //         console.warn('unknown ability', abilityGridName, 'for', puuid)
                //         return []
                //       }
                //       if (ability.slot === 'Ultimate') return []
                //       return states
                //         .map((state, idx, arr) => {
                //           const prevState = arr[idx - 1]
                //           if (!prevState || prevState.ready === state.ready) return
                //           return {
                //             round_time_millis: state.t * 1000,
                //             puuid,
                //             utility: ability.slot.toLowerCase(),
                //             count: state.ready ? 1 : 0,
                //             oldCount: prevState.ready ? 1 : 0,
                //             maxCount: 1,
                //             oldMaXCount: 1,
                //           }
                //         })
                //         .filter(Boolean)
                //     })
                // )
                // console.log('utilitiesUsage', utilitiesUsage)

                const filter = () => true // raw => raw.round_time_millis <= maxTime
                const processedData = prepareMinimapDetectedData(vodProcessedData[roundId], {
                  filter,
                  includePositions: true,
                  includeSmokes: true,
                  includeUtilities: true,
                  includeWalls: true,
                  mapRotatedDeg: 0,
                })
                console.log(roundId, 'processedData', processedData)
                if (!processedData) return

                roundAdvancedPositionsData[roundId] = processedData.positions
                roundSmokesData[roundId] = processedData.smokes
                roundUtilitiesData[roundId] = processedData.utilities
                roundWallsData[roundId] = processedData.walls
              })
          }
        )

      const data = await processMapToolData({
        staticData: { agentsData: this.agentsList, weaponsData: this.weaponsList, gearsData: this.gearsList },
        matchesData,
        orbsData,
        filterSuspiciousAdvancedPositions: false,
        roundAdvancedPositionsData,
        excludeSomeAbilities: false,
        roundUtilitiesUsageData: Object.fromEntries(
          rawInput.flatMap(({ state, matchId }) =>
            state.rounds.map((round, roundNum) => [
              genRoundId(matchId, roundNum),
              round.phases.IN_ROUND?.startTime
                ? [
                    ...(round.phases.ROUND_STARTING?.abilities || []).slice(-20),
                    ...(round.phases.IN_ROUND?.abilities || []),
                    ...(round.phases.ROUND_ENDING?.abilities || []),
                  ].map(utility => ({
                    puuid: utility.playerId,
                    round_time_millis: utility.gameTime - round.phases.IN_ROUND.startTime,
                    utility: utility.abilitySlot,
                    count: 0,
                    oldCount: utility.chargesConsumed,
                  }))
                : [],
            ])
          )
        ),
        roundSmokesData,
        roundUtilitiesData,
        roundWallsData,
        hasKillLocations: matchesData.some(match =>
          match.rounds.some(round => round.kills.some(kill => kill.killer_location?.x != null))
        ),
        reportMissingLocations: false,
      })

      window.mapData = data

      return {
        canAccessPlayback: true,
        canAccessUtilities: true,
        hasApiKills: false,
        hasApiPositions: false,
        hasEconomy: false,
        hasOutcome: false,
        hasPlants: false,
        hasReports: false,
        hasWins: false,
        hasVod: false,
        isScrim: true,
        createBookmarkApi: this.createBookmark,
        data: {
          ...data,
          map: mapData,
          matchesData: Object.fromEntries(matchesData.map(match => [match.id, Object.freeze(match)])),
        },
        map,
        notes: [],
        noteTags: this.noteTags,
        team: Object.keys(data.teams).pop(),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-wrapper,
.map-wrapper2 {
  position: relative;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  overflow: hidden;
}
</style>
