import store from '@/store'

import { findMatches } from '../api/search.js'

/**
 * Tries to find the matches that correspond to the grid series
 * @param {{
 *   startedAt: string,
 *   valid: boolean,
 *   games: Array<{
 *     map?: {name?: string},
 *     segments: Array<{
 *       sequenceNumber: number,
 *       teams: Array<{
 *         id: number,
 *         side: 'attacker'|'defender',
 *       }>,
 *     }>,
 *     teams: Array<{
 *       id: number,
 *       score: number,
 *       players: Array<{
 *         participationStatus: 'active'|any,
 *         character?: {name?: string},
 *       }|null>
 *     }>,
 *   }>,
 * }} seriesState
 * @param config
 * @return {Promise<unknown[]>}
 */
async function findEsportMatchesFromGridSeries(seriesState, config, map) {
  return await Promise.all(
    seriesState.games.map(async game => {
      if (
        map &&
        store.getters['static/mapsByAnything'][game.map?.name?.toLowerCase()] !==
          store.getters['static/mapsByAnything'][map]
      ) {
        return null
      }
      const compositions = game.teams.map(team =>
        team.players
          .filter(player => player && player.participationStatus === 'active' && player?.character?.name)
          .map(player => player.character.name.toLowerCase())
          .filter((name, index, array) => array.indexOf(name) === index)
          .map(character => store.getters['static/agentsByNameLowercase'][character]?.id.toLowerCase())
          .filter(Boolean)
          .sort((a, b) => a.localeCompare(b))
      )
      if (compositions.some(comp => comp.length !== 5)) {
        console.log('missing agent', seriesState, game)
      }
      try {
        const { matches: potentialMatches } = await findMatches(
          {
            // has_vod: true,
            agents_and: compositions[0],
            vs_agents_and: compositions[1],
            map_ids: [store.getters['static/mapsByAnything'][game.map?.name?.toLowerCase()].id],
            period: {
              begin_time: Date.parse(seriesState.startedAt) - 10 * 60 * 1000,
              end_time: Date.parse(seriesState.startedAt) + 10 * 60 * 60 * 1000,
            },
            limit: 50,
            offset: 0,
          },
          config
        )
        // if (potentialMatches.length !== 1 && total !== 1) {
        //   console.log('non unique potential matches', seriesState, game, potentialMatches)
        // }
        const matches = potentialMatches.filter(match => {
          return game.teams
            .map((gridTeam, idx) => ({
              gridTeam,
              gridComposition: compositions[idx],
              gridSide:
                game.segments.find(segment => segment.sequenceNumber <= 12)?.teams.find(t => t.id === gridTeam.id)
                  ?.side === 'attacker'
                  ? 'Red'
                  : 'Blue',
            }))
            .map(({ gridTeam, gridComposition, gridSide }) => ({
              gridTeam,
              esportTeam: match.teams.find(esportTeam => {
                if (esportTeam.team_side !== gridSide) return false
                const c = new Set(esportTeam.agent_ids)
                return gridComposition.every(i => c.has(i))
              }),
            }))
            .every(({ gridTeam, esportTeam }) => {
              if (!esportTeam) return false
              return !seriesState.valid || gridTeam.score === esportTeam.rounds_won
            })
        })
        if (matches.length !== 1) {
          if (matches.length > 0) {
            console.log('non unique matches', seriesState, game, matches)
          } else if (potentialMatches.length > 0) {
            console.log('no matching match', seriesState, game, potentialMatches)
          }
          return null
        }
        return matches.pop()
      } catch (e) {
        console.warn('Error searching for esport matches', e)
        return null
      }
    })
  )
}

export default findEsportMatchesFromGridSeries
