const regexes = [/[\s\-_,]/, /[\W]/, /[aieouäöü]/, /[a-z]/, /[AIEOUÄÖÜ]/, /[A-Z0-9]/]

const digraphs = ['ch', 'gh', 'gn', 'kn', 'ph', 'qu', 'sh', 'th', 'wh', 'wr']

const diblends = ['bl', 'br', 'cl', 'cr', 'fl', 'fr', 'gl', 'gr', 'pl', 'pr', 'sc', 'sl', 'sm', 'sn', 'sp', 'st']

const trigraphs = ['chr', 'sch']

const triblends = ['shr', 'spl', 'spr', 'squ', 'str', 'thr']

function abbrv(str, { length = 3, keepSeparators = false, strict = true } = {}) {
  if (length <= 0 && strict) {
    return ''
  }
  if (length >= str.length) {
    return str
  }

  // trim separators
  str = str.replace(/^[\s\-_,]+/, '').replace(/[\s\-_,]+$/, '')
  if (length >= str.length) {
    return str
  }

  let chars = str.split('')
  let pos = 1
  const order = [pos]
  let orderedCount = 1
  let word = 1
  const words = [1]
  let sep = 0
  let newWord = false

  // forward search for word beginnings
  let i = 1
  while (i < chars.length) {
    order.push(0)
    if (chars[i].search(regexes[0]) > -1) {
      // separator found
      words.push(0)
      newWord = true
      sep++
    } else {
      if (newWord) {
        newWord = false
        word++
        pos++
        order[i] = pos
        orderedCount++
        let found = false
        if (i < chars.length + 2) {
          // search for trigraphs/triblends
          for (const tri of trigraphs.concat(triblends)) {
            if (
              tri[0] === chars[i].toLowerCase() &&
              tri[1] === chars[i + 1].toLowerCase() &&
              tri[2] === chars[i + 2].toLowerCase()
            ) {
              found = true
              break
            }
          }
        }
        if (found) {
          pos++
          order.push(pos)
          orderedCount++
          pos++
          order.push(pos)
          orderedCount++
          words.push(word)
          words.push(word)
          i++
          i++
        } else if (i < chars.length + 1) {
          // search for digraphs/diblends
          for (const di of digraphs.concat(diblends)) {
            if (di[0] === chars[i].toLowerCase() && di[1] === chars[i + 1].toLowerCase()) {
              found = true
              break
            }
          }
          if (found) {
            pos++
            order.push(pos)
            orderedCount++
            words.push(word)
            i++
          }
        }
      }
      words.push(word)
    }
    i++
  }
  if (!strict) {
    let should = word
    if (keepSeparators) {
      should += sep
    }
    if (length < should) {
      length = should
    }
  }

  // backward search for separators
  if (keepSeparators) {
    i = 0
    while (i < chars.length) {
      if (words[i] === 0) {
        order[i] = pos
        orderedCount++
        pos++
      }
      i++
    }
    pos = chars.length
  } else {
    pos = chars.length
    i = chars.length
    while (i > 0) {
      i--
      if (words[i] === 0) {
        order[i] = pos
        orderedCount++
        pos--
      }
    }
  }

  // backward search for remaining chars
  let j = 1
  let unfinished = true
  while (j < regexes.length && unfinished) {
    i = chars.length
    while (i > 0) {
      i--
      if (!(order[i] > 0)) {
        if (chars[i].search(regexes[j]) > -1) {
          order[i] = pos
          orderedCount++
          pos--
          if (orderedCount === chars.length) {
            unfinished = false
            break
          }
        }
      }
    }
    j++
  }
  chars = chars.map(function (val, i) {
    if (order[i] <= length) {
      return val
    } else {
      return ''
    }
  })
  return chars.join('')
}

export default abbrv
