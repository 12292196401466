import { HttpReader, ZipReader } from '@zip.js/zip.js'

import { NotAuthorizedError } from '@/api/errors'
import store from '@/store'
import reduceGridRiotEvents from '@/utils/reduceGridRiotEvents'

import axios from '../axios.js'

const CENTRAL_DATA_API_PATH = '/central-data/graphql'
const LIVE_DATA_API_PATH = '/live-data-feed/series-state/graphql'
const FILE_DOWNLOAD_API_PATH = '/file-download/list'

const GRID_ORIGIN = 'https://api.grid.gg'
const GRID_PROXY_ORIGIN = `${global.location.origin}/proxy/grid`

export async function gridGraphQl({ path, query, variables = {} }, { apiKey, signal, cancelToken } = {}) {
  if (apiKey) {
    const response = await fetch(new URL(path, GRID_ORIGIN).toString(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey,
      },
      body: JSON.stringify({
        query,
        variables,
      }),
      signal,
    })

    if (!response.ok) {
      switch (response.status) {
        case 401:
          throw new NotAuthorizedError()
        default:
          throw new Error(`Grid graphql request failed: ${response.statusText}\n${await response.text()}`)
      }
    }

    return response.json()
  } else {
    const response = await axios.post(
      `${GRID_PROXY_ORIGIN}${path}`,
      JSON.stringify({
        query,
        variables,
      }),
      {
        cancelToken,
        signal,
      }
    )
    return response.data
  }
}

export async function getEmeaBroadcastMatch({ id }, { cancelToken } = {}) {
  const response = await axios.get(`/../proxy/riotemea_val_esport/series/${encodeURIComponent(id)}/matches`, {
    cancelToken,
  })

  return response.data
}

export async function getEmeaBroadcastMapDetails({ id, map }, { cancelToken } = {}) {
  const response = await axios.get(
    `/../proxy/riotemea_val_riot_livestats_timeseries/${encodeURIComponent(id)}/${map}`,
    {
      cancelToken,
    }
  )

  return response.data
}

const generateEmeaMatchId = ({ seriesId, mapName }) => `${seriesId}-${mapName}`

export async function getEmeaMatchBookmarks({ seriesId, mapName }, { cancelToken } = {}) {
  try {
    const { data: signedUrl } = await axios.get(
      `/../proxy/riotemea_val_esport/bookmarks/${encodeURIComponent(generateEmeaMatchId({ seriesId, mapName }))}`,
      {
        cancelToken,
      }
    )

    const response = await fetch(signedUrl, { signal: cancelToken && cancelToken.signal })

    if (!response.ok) {
      return []
    }

    const bookmarks = await response.json()
    return bookmarks.map(b => ({ ...b, match: `${seriesId}.${store.getters['static/mapsByAnything'][mapName].name}` }))
  } catch (e) {
    return []
  }
}

export async function setEmeaMatchBookmarks({ seriesId, mapName, bookmarks }) {
  const { data: signedUrl } = await axios.post(`/../proxy/riotemea_val_esport/bookmarks`, {
    id: generateEmeaMatchId({ seriesId, mapName }),
  })

  const response = await fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(bookmarks),
  })
  if (!response.ok) {
    throw new Error(`Failed to set EMEA match bookmarks: ${response.statusText}: ${await response.text()}`)
  }
  return await response.json()
}

export async function checkGridAccess(opts) {
  const res = await gridGraphQl(
    {
      path: CENTRAL_DATA_API_PATH,
      query: `
      query GetHistoricalSeries {
        allSeries(filter: { type: SCRIM, titleId: 6 }) {
          edges {
            node {
              id
            }
          }
        }
      }
      `,
    },
    opts
  )

  return res.data && !res.error
}

export async function gridEmeaSeriesStateMapTool({ id }, opts) {
  return gridGraphQl(
    {
      path: LIVE_DATA_API_PATH,
      query: `
        query MyQuery {
          seriesState(id: ${id}) {
            startedAt
            valid
            games(filter: {started:true, finished: true}) {
              id
              sequenceNumber
              map {
                name
              }
              teams {
                id
                name
                score
                won
                players {
                  id
                  character {
                    id
                    name
                  }
                  name
                  participationStatus
                }
              }
              segments {
                sequenceNumber
                started
                finished
                teams {
                  id
                  side
                }
              }
            }
          }
        }
      `,
    },
    opts
  )
}

export async function getRiotLiveStatsMatchInfo({ id, map }, { apiKey, cancelToken } = {}) {
  const response = await axios.get(
    `/../proxy/riotemea_val_riot_livestats_matchinfo/grid/series/${encodeURIComponent(id)}/${encodeURIComponent(map)}`,
    {
      ...(apiKey ? { headers: { 'x-grid-api-key': apiKey } } : {}),
      cancelToken,
    }
  )
  return response.data
}

export async function getMatchDetails({ seriesId } = {}, opts) {
  if (!seriesId) {
    throw new Error('Missing Series ID')
  }

  return gridGraphQl(
    {
      path: LIVE_DATA_API_PATH,
      query: `
        query GetSeriesPlayersAndResults {
          seriesState(id: ${seriesId}) {
            id
            started
            finished
            startedAt
            updatedAt
            valid
            games {
              id
              started
              finished
              map {
                name
              }
              segments {
                sequenceNumber
                teams {
                  ... on SegmentTeamStateValorant {
                    name
                    side
                    id
                    won
                    winType
                  }
                }
              }
              teams {
                id
                name
                side
                score
                won
                players {
                  id
                  name
                  participationStatus
                  character {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `,
    },
    opts
  )
}

export async function getMatchFiles({ seriesId }, { apiKey, signal, cancelToken } = {}) {
  if (!seriesId) {
    throw new Error('Missing Series ID')
  }

  if (apiKey) {
    const response = await fetch(
      `${new URL(FILE_DOWNLOAD_API_PATH, GRID_ORIGIN).toString()}/${seriesId}?${new URLSearchParams({ key: apiKey })}`,
      {
        method: 'GET',
        signal,
      }
    )

    if (!response.ok) {
      switch (response.status) {
        case 401:
          throw new NotAuthorizedError()
        default:
          throw new Error(`Failed to get match files: ${response.statusText}\n${await response.text()}`)
      }
    }

    return response.json()
  } else {
    const response = await axios.get(`${GRID_PROXY_ORIGIN}${FILE_DOWNLOAD_API_PATH}/${seriesId}`, {
      cancelToken,
      signal,
    })
    return response.data
  }
}

export async function getEsportMatchIds({ period, cursor, teamIds, tournamentIds, seriesType }, opts) {
  return gridGraphQl(
    {
      path: CENTRAL_DATA_API_PATH,
      query: `
        query GetHistoricalSeries(
          $windowStartTime: String!
          $windowEndTime: String!
          $after: Cursor
          $first: Int
          $livePlayerIds: [ID!]
          $teamIds: [ID!]
          $tournamentIds: [ID!]
          $type: SeriesType!
        ) {
          allSeries(
            filter: {
              startTimeScheduled: { lte: $windowEndTime, gte: $windowStartTime }
              livePlayerIds: { in: $livePlayerIds }
              tournamentIds: { in: $tournamentIds }
              teamIds: { in: $teamIds }
              type: $type
              titleId: 6
            }
            first: $first
            after: $after
            orderBy: StartTimeScheduled
            orderDirection: DESC
          ) {
            totalCount
            pageInfo {
              hasPreviousPage
              hasNextPage
              startCursor
              endCursor
            }
            edges {
              node {
                id
                startTimeScheduled
                format {
                  id
                  name
                  nameShortened
                }
                tournament {
                  id
                  logoUrl
                  name
                  nameShortened
                }
                teams {
                  baseInfo {
                    id
                    logoUrl
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        first: 10,
        ...(period?.created_lte
          ? { windowEndTime: period?.created_lte }
          : { windowEndTime: new Date(Date.now() + 3 * 60 * 60 * 1000) }),
        ...(period?.created_gte
          ? { windowStartTime: period?.created_gte }
          : { windowStartTime: new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toISOString() }),
        ...(cursor ? { after: cursor } : {}),
        ...(teamIds ? { teamIds } : {}),
        ...(tournamentIds ? { tournamentIds } : {}),
        ...(seriesType ? { type: seriesType } : { type: 'ESPORTS' }),
      },
    },
    opts
  )
}

export const getSeriesDetails = async ({ seriesId }, ops) => {
  if (!seriesId) {
    throw new Error('Missing Series ID')
  }

  return gridGraphQl(
    {
      path: CENTRAL_DATA_API_PATH,
      query: `
      query GetHistoricalSeries($seriesId: ID!) {
        series(id: $seriesId) {
          id
          startTimeScheduled
          format {
            id
            name
            nameShortened
          }
          tournament {
            id
            logoUrl
            name
            nameShortened
          }
          teams {
            baseInfo {
              id
              logoUrl
              name
            }
          }
        }
      }
      `,
      variables: {
        seriesId: seriesId,
      },
    },
    ops
  )
}

export const getTeamsAutocomplete = async ({ search }, ops) => {
  return gridGraphQl(
    {
      path: CENTRAL_DATA_API_PATH,
      query: `
        query GetHistoricalSeries($after: Cursor, $first: Int, $nameQuery: String) {
          teams(
            filter: { titleId: 6, name: { contains: $nameQuery } }
            first: $first
            after: $after
          ) {
            edges {
              node {
                id
                name
                logoUrl
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
          }
        }
      `,
      variables: {
        first: 10,
        nameQuery: search,
      },
    },
    ops
  )
}

export const getTournamentsAutocomplete = async ({ search }, ops) => {
  return gridGraphQl(
    {
      path: CENTRAL_DATA_API_PATH,
      query: `
        query GetHistoricalSeries($after: Cursor, $first: Int, $nameQuery: String) {
          tournaments(
            filter: { titleId: 6, name: { contains: $nameQuery } }
            first: $first
            after: $after
          ) {
            edges {
              node {
                id
                name
                logoUrl
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
            totalCount
          }
        }
      `,
      variables: {
        first: 10,
        nameQuery: search,
      },
    },
    ops
  )
}

export const getGridRiotLiveEventsTimeSeries = async (
  { seriesId, mapName },
  { apiKey, signal, cancelToken, localCache } = {}
) => {
  if (!apiKey && store.getters['auth/can_access_grid_emea_matches']) {
    try {
      const resp = await axios.get(
        `/../proxy/riotemea_val_riot_livestats_timeseries/${encodeURIComponent(seriesId)}/${encodeURIComponent(
          mapName
        )}`,
        { cancelToken, signal }
      )
      return resp.data?.state
    } catch (e) {
      console.log('failed loading prepared riot data', e)
      return null
    }
  }

  if (apiKey) {
    const reader = new ZipReader(
      new HttpReader(
        localCache
          ? `${location.origin}/events_${seriesId}_riot.jsonl.zip`
          : `https://api.grid.gg/file-download/events/riot/series/${encodeURIComponent(
              seriesId
            )}?key=${encodeURIComponent(apiKey)}`,
        { preventHeadRequest: true }
      ),
      { signal }
    )
    const [entry] = await reader.getEntries()

    const state = await entry.getData(
      new (class {
        constructor() {
          let remaining = ''
          this.state = reduceGridRiotEvents.INIT
          const writer = this
          const decoder = new TextDecoder()
          const writable = new WritableStream({
            write(chunk) {
              const newLines = ((remaining || '') + decoder.decode(chunk)).split(/\r?\n/)
              remaining = newLines.pop()
              writer.state = newLines
                .filter(Boolean)
                .map(l => JSON.parse(l))
                .reduce((state, event) => reduceGridRiotEvents(state, event), writer.state)
            },
          })
          Object.defineProperty(writer, 'writable', {
            get() {
              return writable
            },
          })
        }

        init() {
          this.initialized = true
        }

        getData() {
          return this.state
        }
      })(),
      {
        onstart(total) {
          console.log('zip start', total)
        },
        onprogress(progress, total) {
          console.log('zip progress', progress, total)
        },
        onend(computedSize) {
          console.log('zip end', computedSize)
        },
      }
    )

    const map = store.getters['static/mapsByAnything'][mapName]
    return Object.values(state.games).find(
      game => store.getters['static/mapsByAnything'][game.configuration.selectedMap] === map
    )
  }

  throw new Error('Missing API Key not emea broadcast access!')
}

export const hasGridRiotLiveEventsTimeSeries = async (
  { seriesId, mapName },
  { apiKey, signal, cancelToken, localCache } = {}
) => {
  try {
    if (!apiKey && store.getters['auth/can_access_grid_emea_matches']) {
      try {
        const resp = await axios.head(
          `/../proxy/riotemea_val_riot_livestats_timeseries/${encodeURIComponent(seriesId)}/${encodeURIComponent(
            mapName
          )}`,
          { cancelToken, signal }
        )
        console.log(resp)
        return resp.status === 200
      } catch (e) {
        console.log('failed loading prepared riot data', e)
      }
    }

    const state = await getGridRiotLiveEventsTimeSeries(
      { seriesId, mapName },
      { apiKey, signal, cancelToken, localCache }
    )
    return (
      store.getters['static/mapsByAnything'][state?.configuration?.selectedMap] ===
      store.getters['static/mapsByAnything'][mapName]
    )
  } catch (e) {
    console.debug('failure getting grid riot live events time series', e)
    return false
  }
}

export async function getGridSeriesInfo({ seriesId }, opts = {}) {
  if (!seriesId) {
    throw new Error('Missing Series ID')
  }

  return await gridGraphQl(
    {
      path: CENTRAL_DATA_API_PATH,
      query: `
        query GetHistoricalSeries($seriesId: ID!) {
          series(id: $seriesId) {
            id
            type
            streams {
              url
            }
            tournament {
              id
              logoUrl
              name
              nameShortened
            }
            teams {
              baseInfo {
                id
                colorPrimary
                colorSecondary
                logoUrl
                name
              }
            }
          }
        }
        `,
      variables: {
        seriesId: seriesId,
      },
    },
    opts
  )
}
