/**
 * Computes the team's role for a give round
 * @param {number} amount
 * @param {number} round
 * @param {boolean} isScrim
 * @return {API_TEAM_ECO}
 */
export default function calcTeamEco(amount, round, isScrim = false) {
  if (round === 0 || round === 12) {
    return 'P'
  }

  if (amount == null || amount <= 0 || Number.isNaN(amount)) {
    return null
  }

  if (amount <= 5000) {
    return '$'
  } else if (amount <= 10000) {
    return '$$'
  } else if (amount <= (isScrim ? 16499 : 20000)) {
    return '$$$'
  } else if (amount <= 100000) {
    return '$$$$'
  }
  return null
}

export const calcEcoAmount = (weaponsById, gearsById, team, tabDetections) => {
  if (!tabDetections?.loadouts) {
    return null
  }

  return team.players.reduce((acc, player) => {
    const loadout = calcPlayerLoadout(weaponsById, gearsById, player, tabDetections)
    if (loadout) {
      if (acc === null) {
        acc = 0
      }
      acc += loadout
    }
    return acc
  }, null)
}

export const calcPlayerLoadout = (weaponsById, gearsById, playerTabData) => {
  if (!playerTabData) {
    return null
  }

  let loadout = 0

  if (playerTabData.gear_id) {
    loadout += gearsById[playerTabData.gear_id].cost
  }
  if (playerTabData.weapon_id) {
    loadout += weaponsById[playerTabData.weapon_id].cost
  }

  return loadout
}
