export const RIOT_OUTCOME = {
  DETONATE: 'Detonate',
  ELIMINATION: 'Elimination',
  SPIKE_DEFUSE: 'Defuse',
  TIME_EXPIRED: 'Time',
}

export const RIOT_LIVESTAT_SLOT = {
  ULTIMATE: 'ultimate',
  BACKPACK: 'ability1',
  GRENADE_ABILITY: 'grenade',
  ABILITY_2: 'ability2',
  ABILITY_1: 'ability1',
  PASSIVE: 'passive',
}
